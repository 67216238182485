import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BioPage = () => (
  <Layout>
    <SEO title="Bio" />

    <section>
			<div className="container max-width-adaptive-sm margin-y-lg">
				<div className="text-component text-md">
					<h1 className="text-xl">Bio</h1>
					<p>
            Equally at home in opera, musical theatre, and on the concert stage, Lydia
            Rose Eiche has been praised for her “great warmth, intelligence and power.”
          </p>
          <p>
            She’s sung roles as diverse as Pamina in <em>Zie Magic Flute</em>, Yitzhak in <em>Hedwig
            and the Angry Inch</em>, Mama Euralie in <em>Once on This Island</em>, the bride
            Milica in the Serbian opera <em>Svadba–Wedding</em>, Babette in <em>Beauty and the
            Beast</em>, and the title character in the comic one-act <em>Captain Lovelock</em>.
          </p>
          <p>
            Lydia has performed with the Milwaukee Symphony Orchestra; Skylight Music
            Theatre; Milwaukee Opera Theatre; Third Avenue Playhouse; All In
            Productions; First Stage Children’s Theatre; Sunset Playhouse; Waukesha
            Civic Theatre; Soulstice Theatre; Haylofters; Boulevard Theatre; Capital
            City Theatre; Forte Theatre Company; Plymouth Chorale; Fresco Opera
            Theatre; Opera Orvieto, in Italy; and Miami Music Festival.
          </p>
          <p>
            Lydia studies with Marlee Sabo.
          </p>
					<p className="text-sm"><Link to="/">Return home</Link></p>
				</div>
			</div>
		</section>

  </Layout>
)

export default BioPage
